import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '@/store'
import Axios from 'axios'
import qs from 'qs'
import {
	Message,
	MessageBox
} from 'element-ui'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [{
		path: '/', //首页
		name: 'Home',
		component: () => import('@/views/Home')
	}, {
		path: '/user/:id?', //企业信息
		name: 'User',
		component: () => import('@/views/User')
	}, {
		path: '/bindwx', //绑定微信
		name: 'Bindwx',
		component: () => import('@/views/Bindwx')
	}, {
		path: '/shopTemplate', //店铺模板列表
		name: 'ShopTemplate',
		component: () => import('@/views/ShopTemplate')
	}, {
		path: '/shopTemplate/:id', //店铺模板详情
		name: 'ShopTemplateId',
		component: () => import('@/views/ShopTemplate/ShopTemplateId')
	}, {
		path: '/applet', //微信小程序
		name: 'Applet',
		component: () => import('@/views/Applet')
	}, {
		path: '/leaflet1', //商机订阅
		name: 'Leaflet1',
		component: () => import('@/views/Leaflet1')
	}, {
		path: '/product', //商品列表
		name: 'Product',
		component: () => import('@/views/Product')
	}, {
		path: '/product/release', //发布商品
		name: 'ProductRelease',
		component: () => import('@/views/Product/Release')
	}, {
		path: '/product/revise', //修改商品
		name: 'ProductRevise',
		component: () => import('@/views/Product/Revise'),
		beforeEnter(to, from, next) {
			if (!to.query.id) {
				next('/404')
			} else {
				next()
			}
		}
	}, {
		path: '/product/copy', //复制商品
		name: 'ProductCopy',
		component: () => import('@/views/Product/Copy'),
		beforeEnter(to, from, next) {
			if (!to.query.id) {
				next('/404')
			} else {
				next()
			}
		}
	}, {
		path: '/setShop', //店铺设置
		name: 'SetShop',
		component: () => import('@/views/SetShop'),
		children: [{
				path: 's0',
				name: 'SetShop0',
				component: () => import('@/views/SetShop/S0')
			}, {
				path: 's1',
				name: 'SetShop1',
				component: () => import('@/views/SetShop/S1')
			}, {
				path: 's2',
				name: 'SetShop2',
				component: () => import('@/views/SetShop/S2')
			}, {
				path: 's3',
				name: 'SetShop3',
				component: () => import('@/views/SetShop/S3')
			},
			{
				path: 's4',
				name: 'SetShop4',
				component: () => import('@/views/SetShop/S4')
			},
			{
				path: 's5',
				name: 'SetShop5',
				component: () => import('@/views/SetShop/S5')
			},
			{
				path: '/setShop',
				redirect: 's0'
			}
		]
	}, {
		path: '/news', //新闻管理
		name: 'News',
		component: () => import('@/views/News')
	}, {
		path: '/news/release', //发布新闻
		name: 'NewsRelease',
		component: () => import('@/views/News/Release')
	}, {
		path: '/news/revise', //修改新闻
		name: 'NewsRevise',
		component: () => import('@/views/News/Revise'),
		beforeEnter(to, from, next) {
			if (!to.query.id) {
				next('/404')
			} else {
				next()
			}
		}
	}, {
		path: '/cases', //工程示例
		name: 'Cases',
		component: () => import('@/views/Cases')
	}, {
		path: '/cases/release', //发布工程
		name: 'CasesRelease',
		component: () => import('@/views/Cases/Release')
	}, {
		path: '/cases/revise', //修改工程
		name: 'CasesRevise',
		component: () => import('@/views/Cases/Revise'),
		beforeEnter(to, from, next) {
			if (!to.query.id) {
				next('/404')
			} else {
				next()
			}
		}
	}, {
		path: '/cusCl', //自定义分类
		name: 'CusCl',
		component: () => import('@/views/CusCl')
	}, {
		path: '/order', //订单管理
		name: 'Order',
		component: () => import('@/views/Order')
	}, {
		path: '/purchase', //采购管理
		name: 'Purchase',
		component: () => import('@/views/Purchase')
	}, {
		path: '/purchase/release', //发布采购
		name: 'PurchaseRelease',
		component: () => import('@/views/Purchase/Release')
	}, {
		path: '/purchase/revise', //修改采购
		name: 'PurchaseRevise',
		component: () => import('@/views/Purchase/Revise'),
		beforeEnter(to, from, next) {
			if (!to.query.id) {
				next('/404')
			} else {
				next()
			}
		}
	}, {
		path: '/assets', //资产管理
		name: 'Assets',
		component: () => import('@/views/Assets')
	}, {
		path: '/assets/cashing', //提现
		name: 'Cashing',
		component: () => import('@/views/Assets/Cashing')
	}, {
		path: '/integral', //积分
		name: 'Integral',
		component: () => import('@/views/Integral')
	}, {
		path: '/integral/recharge', //充值
		name: 'IntegralRecharge',
		component: () => import('@/views/Integral/Recharge')
	}, {
		path: '/authen', //企业认证
		name: 'Authen',
		component: () => import('@/views/Authen'),
		children: [{
			path: 's1',
			name: 'Authen1',
			component: () => import('@/views/Authen/S1')
		}, {
			path: 's2',
			name: 'Authen2',
			component: () => import('@/views/Authen/S2')
		}, {
			path: 's3',
			name: 'Authen3',
			component: () => import('@/views/Authen/S3')
		}, {
			path: '/authen',
			redirect: 's1'
		}]
	}, {
		path: '/album', //相册管理
		name: 'Album',
		component: () => import('@/views/Album')
	}, {
		path: '/company', //公司要闻
		name: 'Company',
		component: () => import('@/views/Company')
	},
	{
		path: '/company/release', //发布新闻
		name: 'CompanyRelease',
		component: () => import('@/views/Company/Release')
	}, {
		path: '/company/revise', //修改新闻
		name: 'CompanyRevise',
		component: () => import('@/views/Company/Revise')
	},
	{
		path: '/honor', //荣誉资质
		name: 'Honor',
		component: () => import('@/views/Honor')
	},
	{
		path: '/honor/release', //发布荣誉资质
		name: 'HonorRelease',
		component: () => import('@/views/Honor/Release')
	}, {
		path: '/honor/revise', //修改荣誉资质
		name: 'HonorRevise',
		component: () => import('@/views/Honor/Revise')
	},
	{
		path: '/album/list', //相册管理
		name: 'AlbumList',
		component: () => import('@/views/Album/List'),
		beforeEnter(to, from, next) {
			if (!to.query.id) {
				next('/404')
			} else {
				next()
			}
		}
	}, {
		path: '/newest', //最新研发
		name: 'Newest',
		component: () => import('@/views/Newest')
	}, {
		path: '/newest/release', //发布最新研发
		name: 'NewestRelease',
		component: () => import('@/views/Newest/Release')
	}, {
		path: '/newest/revise', //修改最新研发
		name: 'NewestRevise',
		component: () => import('@/views/Newest/Revise')
	}, {
		path: '/industry', //行业应用
		name: 'Industry',
		component: () => import('@/views/Industry')
	}, {
		path: '/industry/release', //发布行业应用
		name: 'IndustryRelease',
		component: () => import('@/views/Industry/Release')
	}, {
		path: '/industry/revise', //修改行业应用
		name: 'IndustryRevise',
		component: () => import('@/views/Industry/Revise')
	}, {
		path: '/recruitment', //招聘信息
		name: 'Recruitment',
		component: () => import('@/views/Recruitment')
	}, {
		path: '/recruitment/release', //发布行业应用
		name: 'RecruitmentRelease',
		component: () => import('@/views/Recruitment/Release')
	}, {
		path: '/recruitment/revise', //修改行业应用
		name: 'RecruitmentRevise',
		component: () => import('@/views/Recruitment/Revise')
	}, {
		path: '/miss', //升级高级会员
		name: 'Miss',
		component: () => import('@/views/Miss')
	}, {
		path: '/miss/sx', //升级首席会员
		name: 'sx',
		component: () => import('@/views/Miss/Sx')
	},{
		path: '/announcement', //系统公告
		name: 'Announcement',
		component: () => import('@/views/Announcement')
	},{
		path: '/announcement/read', //查看消息
		name: 'read',
		component: () => import('@/views/Announcement/read')
	},{
		path: '/announcement/open', //已读消息
		name: 'open',
		component: () => import('@/views/Announcement/open')
	},{
		path: '/announcement/noOpen', //未读消息
		name: 'noOpen',
		component: () => import('@/views/Announcement/noOpen')
	},{
		path: '/auszahlung', //申请支付
		name: 'Auszahlung',
		component: () => import('@/views/Auszahlung')
	},{
		path: '/auszahlung/trading', //申请2
		name: 'trading',
		component: () => import('@/views/Auszahlung/trading')
	},{
		path: '/auszahlung/check', //核对申请
		name: 'check',
		component: () => import('@/views/Auszahlung/check')
	},
	// {
	// 	path: '/auszahlung/audit', //审核中
	// 	name: 'audit',
	// 	component: () => import('@/views/Auszahlung/audit')
	// },
	{
		path: '/tiktok', //抖音获客
		name: 'Tiktok',
		component: () => import('@/views/Tiktok')
	},{
		path: '/tiktok/customer', //客户列表
		name: 'Tiktok',
		component: () => import('@/views/Tiktok/customer')
	},{
		path: '/tiktok/video', //视频列表
		name: 'Tiktok',
		component: () => import('@/views/Tiktok/video')
	},{
		path: '/404',
		name: '404',
		component: () => import('@/views/Error/404')
	}, {
		path: '*',
		redirect: '/404'
	}
]

const router = new VueRouter({
	base: process.env.BASE_URL,
	routes
})
let remark;
 router.beforeEach((to, from, next) => {
	let {
		USER_ID,
		I_SHOP
	} = Store.state
	
	if (USER_ID && I_SHOP) {
		judShop(I_SHOP, to, from, next)
	} else {
		Axios.post(`${Store.state.BASE_URL}/getsellerinfo`).then(res => {
			if (res.data.code == 41000) {
				location.href = Store.state.USER_URL + '/login'
			}
			const data = res.data.data
			
			if(data.remark != null){
				remark = data.remark
			}else{
				remark = ''
			}
			Store.commit('SET_USER_ID', data.userid)
			Store.commit('SET_SELLER_ID', data.seller_id)
			Store.commit('SET_I_SHOP', data.ishop)
			Store.commit('SET_HEAD_IMG', data.headimg)
			Store.commit('SET_USER_NAME', data.username)
			Store.commit("SET_I_EVEL",data.level)
			Store.commit("SET_FLAG_SHIP",data.flagship)
			return Store.state.I_SHOP
		}).then(ishop => {
			judShop(ishop, to, from, next)
		})
	}
})

function judShop(i_shop, to, from, next) {
	i_shop *= 1
	// console.log("i_shop",i_shop)
	switch (i_shop) {
		case 0:
			if (to.name.includes('Authen')) {
				next()
			} else {
				MessageBox.alert('店铺正在审核中', '提示', {
					type: 'warning'
				}).then(() => {
					//history.back(-1)
					location.href = Store.state.USER_URL+'/#/member'
				}).catch(() => {
					location.href = Store.state.USER_URL+'/#/member'
				})
			}
			break
		case 1:
			if (to.name.includes('Authen')) {
				next('/404')
			} else {
				next()
			}
			break
		case 2:
			if (to.name.includes('Authen')) {
				next()
			} else {
				MessageBox.alert('请您先填写资料申请店铺', '提示', {
					type: 'warning'
				}).then(() => {
					next('/authen')
				})
			}
			break
		case 3:
			if (to.name.includes('Authen')) {
				next()
			} else {
				if(remark != ''){
					MessageBox.alert(remark, '提示', {
						type: 'error'
					}).then(() => {
						next("/authen")
					})
				}else{
					MessageBox.alert('您的店铺审核未通过，点击确定修改后重新提交', '提示', {
						type: 'error'
					}).then(() => {
						next("/authen")
					})
				}
				
			}
			break
		default:
			location.href = Store.state.USER_URL + '/login'
			break
	}
} 

export default router
