import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element'
import './plugins/vueAreaLinkage'
import './plugins/vueTinymce'
import './assets/css/reset.css'
import 'amfe-flexible'
import 'default-passive-events'
import request from './request'
import banksdata from './assets/js/bank.js'
import  'jquery'
import qs from 'qs'
import * as math from 'mathjs'
import 'babel-polyfill'    //兼容360
Vue.prototype.$qs = qs;
Vue.prototype.$axios = request
Vue.config.productionTip = false
if (store.state.USER_ID) {
	store.commit('SET_USER_ID', '')
}

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')


//测试
Vue.prototype.handleAvatar = function(res, file,id){//1营业执照 2 3 身份证  4 银行
	if(id == 1){
		if (res.code == 200) {
			this.form.CompanyName = res.data.data.单位名称
			this.form.company_name = res.data.data.单位名称
			this.form.CompanyCertNo = res.data.data.社会信用代码
			this.form.license_code = res.data.data.社会信用代码
			this.form.Address = res.data.data.地址
			this.form.faren_name = res.data.data.法人
			this.form.license = res.data.src
			this.form.thumb = res.data.src
			this.form.imageUrl = URL.createObjectURL(file.raw);
			let comname = res.data.data.单位名称
			let comcode = res.data.data.社会信用代码
			if (!comname || !comcode) {
				this.$message.error("图片不正确/不清晰,请重新上传~")
			} else {
				this.$message.success("上传成功")
			}
		}else{
			this.$message.error(res.msg)
		}
	}else if(id == 2){
		if (res.code == 200) {
			this.form.faren_cpcode = res.data.data.公民身份号码
			this.form.cperson= res.data.data.姓名
			this.form.faren_name = res.data.data.姓名
			this.form.cpcode=  res.data.data.公民身份号码
			this.form.faren_cphoto_zheng = res.data.src
			this.form.thumb = res.data.src
			this.form.cphoto = res.data.src
			
			this.form.imageUrlA = URL.createObjectURL(file.raw);
			let peocode = res.data.data.公民身份号码
			this.peoname = res.data.data.姓名
			let peoadress = res.data.data.住址
			if (!peocode || !this.peoname || !peoadress) {
				this.$message.error("图片不正确/不清晰,请重新上传~")
			} else {
				this.$message.success("上传成功")
			}
		}else{
			this.$message.error(res.msg)
		}
	}else if(id == 3){
		if (res.code == 200) {
			this.form.faren_cphoto_fan = res.data.src
			this.form.thumb = res.data.src
			this.form.cphoto_back = res.data.src
			this.form.imageUrlB = URL.createObjectURL(file.raw);
			this.$message.success("上传成功")
		}else{
			this.$message.error(res.msg)
		}
	}else if(id == 4){
		if (res.code == 200) {
			this.form.account_licence = res.data.src
			this.form.thumb = res.data.src
			this.form.imageUrlyh = URL.createObjectURL(file.raw);
			this.$message.success("上传成功")
		}else{
			this.$message.error(res.msg)
		}
	}else if(id == 0){
		if(res.code == 200){
			this.form.logo = res.data.src
			this.imageUrl = URL.createObjectURL(file.raw);
		}else{
			this.$message.error(res.msg)
		}
	}else if(id == 5){
		if(res.code == 200){
			this.form.photo = res.data.src
			this.imageUrl = URL.createObjectURL(file.raw);
		}else{
			this.$message.error(res.msg)
		}
	}else if(id == 6){
		if(res.code == 200){
			this.form.thumb = res.data.src
			this.imageUrl = URL.createObjectURL(file.raw);
		}else{
			this.$message.error(res.msg)
		}
	}
}