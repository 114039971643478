import Axios from 'axios'
import Qs from 'qs'
import Store from '@/store'
import {
	Loading,
	MessageBox
} from 'element-ui';
import mgc from '@/assets/js/mgc.js'

const service = Axios.create({
	timeout: 10000,
	baseURL: Store.state.BASE_URL,
	transformRequest: [function(data) {
		let dataObj = ''
		if (!data || Object.keys(data).length == 0) {
			dataObj = JSON.stringify({
				user_id: Store.state.SELLER_ID
			})
		} else {
			if (!data.user_id) {
				data.user_id = Store.state.SELLER_ID
				// JSON.stringify({
				// 	user_id: Store.state.USER_ID
				// })
			}
			dataObj = JSON.stringify(data)
		}
		if (dataObj != null) {
			for (let i = 0; i < mgc.length; i++) {
				dataObj = dataObj.replace(new RegExp(mgc[i], 'gm'), '*')
			}
			dataObj = JSON.parse(dataObj)
		}
		dataObj = Qs.stringify(dataObj);
		return dataObj;
	}]
})
let loading, showLoading = () => {
		loading = Loading.service()
	},
	hiddenLoading = () => {
		if (loading) {
			loading.close()
		}
	},
	errorAlert = () => {
		MessageBox.alert('请刷新后重试', '网络错误', {
			type: 'error',
			showClose: false,
			confirmButtonText: '关闭',
			callback: () => {
				hiddenLoading()
			}
		})
	}
service.interceptors.request.use(
	config => {
		if (config.loading != 'no') {
			showLoading()
		}
		return config
	},
	error => {
		errorAlert()
		Promise.reject(error)
	}
)
service.interceptors.response.use(
	response => {
		if (response.status == 200) {
			hiddenLoading()
		} else {
			errorAlert()
		}
		if (response.data.code == 200) {
			if (response.data.data) {
				return response.data.data
			} else if (response.data.money) {
				return response.data.money
			} else if (response.data.list) {
				return response.data.list
			} else {
				return response.data
			}
		} else if (response.data.code == 41000) {
			location.href = Store.state.USER_URL + '/login'
			return Promise.reject(false)
		} else if (response.data.code == 400001) {
			MessageBox.alert('积分不足', '提示', {
				confirmButtonText: '确定'
			})
			return Promise.reject(false)
		} else {
			return response.data
		}
	},
	error => {
		if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
			MessageBox.alert('请点击重新请求', '请求超时', {
				type: 'error',
				showClose: false,
				confirmButtonText: '重新请求',
				callback: () => {
					return service.request(error.originalRequest)
				}
			})
		} else {
			errorAlert()
		}
		return Promise.reject(error)
	}
)

export default service
