import Vue from "vue";
import {
  Container,
  Header,
  Aside,
  Main,
  Button,
  Image,
  Icon,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Tabs,
  TabPane,
  Form,
  FormItem,
  Input,
  Select,
  OptionGroup,
  Option,
  Upload,
  MessageBox,
  Message,
  Table,
  TableColumn,
  Checkbox,
  Pagination,
  CascaderPanel,
  Radio,
  Switch,
  Loading,
  Popover,
  PageHeader,
  Cascader,
  Dialog,
  Popconfirm,
  RadioGroup,
  DatePicker,
  Steps,
  Step,
  Menu,
  MenuItem,
  Submenu,
} from "element-ui";

Vue.use(Container)
  .use(Header)
  .use(Aside)
  .use(Main)
  .use(Button)
  .use(Image)
  .use(Icon)
  .use(Dropdown)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Tabs)
  .use(TabPane)
  .use(Form)
  .use(FormItem)
  .use(Input)
  .use(Select)
  .use(OptionGroup)
  .use(Option)
  .use(Upload)
  .use(Table)
  .use(TableColumn)
  .use(Checkbox)
  .use(Pagination)
  .use(CascaderPanel)
  .use(Radio)
  .use(Switch)
  .use(Loading)
  .use(Popover)
  .use(PageHeader)
  .use(Cascader)
  .use(Dialog)
  .use(Popconfirm)
  .use(RadioGroup)
  .use(DatePicker)
  .use(Steps)
  .use(Step)
  .use(Menu)
  .use(MenuItem)
  .use(Submenu);
  
  

Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
