<template>
	<el-container id="app">
		<el-container style="height: 98vh;margin-bottom: -20px;">
			<el-header style="height: 80px;text-align: right; font-size: 12px;">
				<div class="left">
					<a :href="$store.state.ROOT_URL" target="_blank">
						<el-image :src="require('@/assets/img/logo.png')" alt="消毒设备网" />
					</a>
					<h2>
						<span>工作台</span>
						<a :href="$store.state.USER_URL" class="switch"><i class="el-icon-news" />切换买家</a>
					</h2>
				</div>
				<el-dropdown @command="handleCommand">
					<router-link to="" class="right">
						<div class="user-img">
							<el-image :src="$store.state.IMG_URL+'/'+$store.state.HEAD_IMG">
								<img src="@/assets/img/user.png" slot="error" class="user-error-img">
							</el-image>
						</div>
						<div class="user-num">{{$store.state.USER_NAME}}<i class="el-icon-arrow-right" /></div>
					</router-link>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="1">基本信息</el-dropdown-item>
						<!-- <el-dropdown-item command="3">我的店铺</el-dropdown-item> -->
						<el-dropdown-item command="2">退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</el-header>
			<el-container class="appCount">
				<el-aside width="240px" scrolling="no" style="height: 89vh;background-color: rgb(238, 241, 246);z-index: 999;">
					<el-menu unique-opened router open :default-openeds="openeds" >
						<el-menu-item index="/">
							<i class="el-icon-monitor"></i>
							<span>概况</span>
						</el-menu-item>
						<el-submenu index="2">
							<template slot="title">
								<i class="el-icon-s-shop"></i>
								<span slot="title">店铺管理</span>
							</template>
							<a style="color: #303133;" :href="$store.state.BASE_URL+'/zhuangxiu'"
									target="_blank"><el-menu-item>店铺装修</el-menu-item></a>
							<el-menu-item index="/ShopTemplate">定制店铺</el-menu-item>
							<a style="color: #303133;" :href="$store.state.ROOT_URL+'/bdxcx'" target="_blank"><el-menu-item>百度小程序
							</el-menu-item></a>
							<el-menu-item index="/applet">微信小程序</el-menu-item>
							<el-menu-item index="/cusCl">店铺产品分类</el-menu-item>
							<el-menu-item index="/setShop">店铺设置</el-menu-item>
							<el-menu-item index="/album">相册管理</el-menu-item>
							<el-menu-item index="/auszahlung">在线交易</el-menu-item>
						</el-submenu>
						<el-submenu index="3">
							<template slot="title">
								<i class="el-icon-document"></i>
								<span slot="title">信息管理</span>
							</template>
							<el-menu-item index="/product">产品管理</el-menu-item>
							<el-menu-item index="/purchase">采购管理</el-menu-item>
							<el-menu-item index="/cases">工程案例</el-menu-item>
							<el-menu-item index="/company">企业要闻</el-menu-item>
							<el-menu-item index="/honor">荣誉资质</el-menu-item>
							<el-menu-item index="/newest">最新研发</el-menu-item>
							<el-menu-item index="/industry">行业应用</el-menu-item>
							<el-menu-item index="/recruitment">招聘信息</el-menu-item>
							<el-menu-item index="/news">资讯管理</el-menu-item>
						</el-submenu>
						<el-submenu index="4">
							<template slot="title">
								<i class="el-icon-user"></i>
								<span slot="title">会员服务</span>
							</template>
							<el-menu-item index="/order">订单管理</el-menu-item>
							<el-menu-item index="/assets">资产管理</el-menu-item>
							<el-menu-item index="/tiktok">D音获客</el-menu-item>
							<el-menu-item index="/integral">积分管理</el-menu-item>
						</el-submenu>
						<el-submenu index="5">
							<template slot="title">
								<i class="el-icon-chat-dot-square"></i>
								<span slot="title">系统公告</span>
							</template>
							<el-menu-item index="/announcement">全部消息</el-menu-item>
							<el-menu-item index="/announcement/noOpen">未读消息</el-menu-item>
							<el-menu-item index="/announcement/open">已读消息</el-menu-item>
						</el-submenu>
					</el-menu>
				</el-aside>
				<el-main style="height: 89vh;background:#f6f5f7;">
					<router-view />
				</el-main>
			</el-container>
		</el-container>
	</el-container>
</template>
<script>
	export default {
		data(){
			return{
				openeds: ['2'],
			}
		},
		methods: {
			handleCommand(command) {
				if (command == 1) {
					this.$router.push('/user')
				}
				if (command == 2) {
					localStorage.clear();
					location.href = this.$store.state.USER_URL + '/login'

				}
			}
		}
	}
</script>
<style>
	#app {
		min-width: 997px;
		width: 100%;
	}
	.appCount{
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	a {
		text-decoration: none;
	}

	ul li {
		list-style: none;
	}

	.el-header {
		background-color: #0194fe;
		color: #333;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.el-header .left {
		display: flex;
		align-items: center;
	}

	.el-header .left h2 {
		color: #fff;
		font-size: 29px;
		font-weight: 400;
		margin-left: 20px;
	}

	.el-header .left .switch {
		font-size: 14px;
		margin-left: 10px;
		color: #fff;
	}

	.el-header .right {
		display: flex;
		align-items: center;
	}

	.user-img {
		width: 46px;
		height: 46px;
		border-radius: 50%;
		overflow: hidden;
		background: #fff;
	}

	.user-error-img {
		width: 46px;
		height: 46px;
	}

	.user-num {
		color: #fff;
		margin-left: 16px;
		font-size: 18px;
	}

	.user-num i {
		margin-right: 17px;
	}

	.el-aside {
		color: #333;
	}

	/* 纵向滚动条 */
	.el-aside::-webkit-scrollbar {
		display: none;
	}
	.el-page-header{
		padding: 20px;
	}
	.add-btn{
		margin:10px 0 20px 20px ;
	}
	.el-table{
		width: 1567px;
		margin: 10px auto;
	}
	.o-wrap,.wrap{
		width: 1600px;
		background-color: #fff;
		padding-bottom: 20px;
		
	}
	.purForm{
		width: 792px;
		margin-left: 20px;
	}
	.wrap .el-tabs__nav-wrap:after{
		background-color: rgba(0,0,0,0) !important;
	}
</style>
