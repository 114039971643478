import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
Vue.use(Vuex)

let ls = new SecureLS({
	encodingType: 'aes',
	isCompression: false,
	encryptionSecret: "old-beauty"
})
export default new Vuex.Store({
	plugins: [
		createPersistedState({
			key: 'xdsb',
			storage: {
				getItem: key => ls.get(key),
				setItem: (key, value) => ls.set(key, value),
				removeItem: key => ls.remove(key)
			}
		})
	],
	// state: {
	// 	WX_STATE: 0,
	// 	USER_ID: '',
	// 	SELLER_ID: '',
	// 	I_SHOP: '',
	// 	I_EVEL:'',
	// 	HEAD_IMG: '',
	// 	USER_NAME: '',
	// 	FLAG_SHIP:'',
	// 	ROOT_URL: '//www.new.chinaxdsb.com',
	// 	IMG_URL: '//img.new.chinaxdsb.com',
	// 	BASE_URL: '//myseller.new.chinaxdsb.com',
	// 	USER_URL: '//user.new.chinaxdsb.com',
	// 	HIMG_URL:'//himg.new.chinaxdsb.com'
	// },
	state: {
		WX_STATE: 0,
		USER_ID: '',
		SELLER_ID: '',
		I_SHOP: '',
		I_EVEL:'',
		HEAD_IMG: '',
		USER_NAME: '',
		FLAG_SHIP:'',
		ROOT_URL: '//www.chinaxdsb.com',
		IMG_URL: '//img.chinaxdsb.com',
		BASE_URL: '//myseller.chinaxdsb.com',
		USER_URL: '//user.chinaxdsb.com',
		HIMG_URL:'//himg.chinaxdsb.com'
	},
	mutations: {
		SET_WX_STATE(state, value) {
			state.WX_STATE = value
		},
		SET_USER_ID(state, value) {
			state.USER_ID = value
		},
		SET_SELLER_ID(state, value) {
			state.SELLER_ID = value
		},
		SET_I_SHOP(state, value) {
			state.I_SHOP = value
		},
		SET_HEAD_IMG(state, value) {
			state.HEAD_IMG = value
		},
		SET_USER_NAME(state, value) {
			state.USER_NAME = value
		},
		SET_I_EVEL(state, value) {
			state.I_EVEL = value
		},
		SET_FLAG_SHIP(state, value) {
			state.FLAG_SHIP = value
		}
	}
})
